import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import ReactWordCloud from 'react-wordcloud';

class App extends Component {

    constructor(props){
        super(props);
        this.state = {
            words : [{"id":"4","text":"danskebankapp","lastupdated":"2019-02-19 09:00:43.793877","value":"2"},{"id":"3","text":"bankid","lastupdated":"2019-02-16 08:34:15.451510","value":"2"},{"id":"5","text":"tingsomikkefunker","lastupdated":"2019-02-16 05:21:16.357779","value":"1"},{"id":"6","text":"spaceworld","lastupdated":"2019-02-17 04:00:21.268838","value":"1"},{"id":"7","text":"nav","lastupdated":"2019-02-18 03:05:41.325541","value":"1"},{"id":"8","text":"mandager","lastupdated":"2019-02-18 03:06:20.992331","value":"1"},{"id":"9","text":"hjemmekontor","lastupdated":"2019-02-18 03:36:54.061109","value":"1"},{"id":"10","text":"kontorjobb","lastupdated":"2019-02-18 03:41:09.609768","value":"1"},{"id":"11","text":"\u00e5pent kontorlandskap","lastupdated":"2019-02-18 03:41:40.974428","value":"1"},{"id":"12","text":"dnbappen","lastupdated":"2019-02-19 05:47:23.332744","value":"1"},{"id":"13","text":"wordcloud","lastupdated":"2019-02-19 14:27:16.645342","value":"1"}]
        }
    }


    componentDidMount() {
        if(window.location.hostname !== "localhost"){

        var url = "/api/getAllBrokenThings/"

        fetch(url).then(
            response => response.json()
        ).then(
            json => {
                console.log(json);
                this.setState({words: json});
            }
        );
        }

    }


  render() {

    const WORD_COUNT_KEY = 'value';
    const WORD_KEY = 'text';

    return (
      <div className="App">
          <div style={{margin: '0 auto', width: window.innerWidth, height: window.innerHeight}}>
              <ReactWordCloud
                  words={this.state.words}
                  wordCountKey={WORD_COUNT_KEY}
                  wordKey={WORD_KEY}
              />
          </div>

      </div>
    );
  }
}

export default App;
